/* Services Section Styles */
#services {
  margin: 0;
  padding: 4rem 0;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 2rem;
}

.section-heading {
  font-size: clamp(2rem, 5vw, 2.5rem);
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  position: relative;
  display: inline-block;
}

.section-heading::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 30%;
  width: 40%;
  height: 3px;
  background: var(--accent-color);
  border-radius: 3px;
}

.section-subheading {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  text-align: center;
  color: var(--text-medium);
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.subsection-heading {
  font-size: clamp(1.3rem, 3vw, 1.6rem);
  color: var(--primary-color);
  text-align: center;
  margin: 3rem 0 2rem;
  position: relative;
  display: inline-block;
}

.subsection-heading::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 25%;
  width: 50%;
  height: 2px;
  background: var(--accent-color);
  border-radius: 2px;
}

/* Featured services styling */
.featured-services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.featured-service-card {
  background: linear-gradient(145deg, #ffffff, #f8f9ff);
  border-radius: 16px;
  padding: 2.5rem 2rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  border: 1px solid rgba(var(--primary-rgb), 0.08);
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.featured-service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(var(--primary-rgb), 0.03) 0%, rgba(var(--accent-rgb), 0.03) 100%);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.featured-service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  border-color: var(--accent-color);
}

.featured-service-card:hover::before {
  opacity: 1;
}

.service-icon-wrapper {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, var(--accent-color), var(--accent-light));
  border-radius: 50%;
  margin-bottom: 1.5rem;
  box-shadow: 0 10px 25px rgba(var(--primary-rgb), 0.15);
  transition: all 0.3s ease;
}

.featured-service-card:hover .service-icon-wrapper {
  transform: scale(1.05) rotate(5deg);
  box-shadow: 0 15px 35px rgba(var(--primary-rgb), 0.2);
}

.service-icon {
  font-size: 2rem;
  color: var(--primary-dark);
}

.featured-service-card h3 {
  font-size: clamp(1.3rem, 3vw, 1.5rem);
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.featured-service-card h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  border-radius: 3px;
  transition: width 0.3s ease;
}

.featured-service-card:hover h3::after {
  width: 60px;
}

.featured-service-card p {
  color: var(--text-medium);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.service-features {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.service-features li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--text-medium);
  transition: transform 0.3s ease;
}

.service-features li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--accent-color);
  font-weight: bold;
}

.featured-service-card:hover .service-features li {
  transform: translateX(3px);
}

.service-link {
  display: inline-flex;
  align-items: center;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.95rem;
  margin-top: 1rem;
  gap: 0.5rem;
  text-decoration: none;
  transition: all 0.3s ease;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.service-link:hover {
  color: var(--secondary-color);
}

.service-link:hover svg {
  transform: translateX(4px);
}

/* Services grid styling */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  margin: 2rem 0 3rem 0;
}

.service-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(var(--primary-rgb), 0.05);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.08);
  border-color: var(--secondary-light);
}

.service-card h4 {
  font-size: 1.25rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.service-card h4::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: var(--secondary-color);
}

.service-card p {
  color: var(--text-medium);
  line-height: 1.6;
  font-size: 0.95rem;
}

/* Services CTA styling */
.services-cta {
  text-align: center;
  margin: 3rem 0 1rem;
}

.view-all-services {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  color: var(--text-light);
  background-color: var(--primary-color);
  box-shadow: 0 8px 20px rgba(var(--primary-rgb), 0.2);
  border: 2px solid var(--primary-color);
}

.view-all-services svg {
  transition: transform 0.3s ease;
  font-size: 0.9rem;
}

.view-all-services:hover {
  background-color: var(--primary-dark);
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(var(--primary-rgb), 0.3);
}

.view-all-services:hover svg {
  transform: translateX(5px);
}

@media (min-width: 992px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .featured-services {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  #services {
    padding: 3rem 0;
  }
  
  .featured-services,
  .services-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .service-icon-wrapper {
    width: 70px;
    height: 70px;
  }
  
  .service-icon {
    font-size: 1.8rem;
  }
  
  .view-all-services {
    width: 100%;
    justify-content: center;
    max-width: 300px;
  }
}
