.about-section {
    width: 100%;
    padding: 0;
    position: relative;
    margin-top: 0;
}

.section-content {
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
    padding: 0 2rem;
}

.section-heading {
    font-size: clamp(2rem, 5vw, 2.5rem);
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    position: relative;
    display: inline-block;
}

.section-heading::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 30%;
    width: 40%;
    height: 3px;
    background: var(--accent-color);
    border-radius: 3px;
}

.section-subheading {
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    color: var(--text-medium);
    margin-bottom: 3rem;
    margin-top: 1.5rem;
    font-weight: 500;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.credential-badge {
    background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
    display: flex;
    align-items: center;
    padding: 1.8rem;
    border-radius: 16px;
    margin: 0 auto 3rem;
    max-width: 600px;
    box-shadow: 0 15px 35px rgba(var(--primary-rgb), 0.15);
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    transform: translateY(0);
    transition: all 0.3s ease;
}

.credential-badge:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(var(--primary-rgb), 0.2);
}

.badge-icon {
    font-size: 2.5rem;
    color: var(--accent-color);
    background: rgba(255, 255, 255, 0.1);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    flex-shrink: 0;
    border: 2px solid var(--accent-color);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.credential-badge:hover .badge-icon {
    transform: scale(1.05) rotate(5deg);
    background: rgba(255, 255, 255, 0.15);
}

.badge-text {
    color: var(--text-light);
}

.badge-text h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: var(--text-light);
}

.badge-text p {
    margin: 0;
    color: white;
    font-weight: 500;
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 2rem 0 4rem;
}

.highlight {
    background: linear-gradient(to bottom right, #fff, rgba(var(--primary-rgb), 0.03));
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    text-align: left;
    transition: all 0.3s ease;
    border: 1px solid rgba(var(--primary-rgb), 0.1);
    position: relative;
    overflow: hidden;
}

.highlight:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    border-color: var(--accent-color);
}

.highlight::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(var(--primary-rgb), 0.02) 0%, rgba(var(--secondary-rgb), 0.05) 100%);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.highlight:hover::before {
    opacity: 1;
}

.highlight h3 {
    font-size: clamp(1.25rem, 3vw, 1.4rem);
    color: var(--primary-color);
    margin-bottom: 1.2rem;
    position: relative;
    padding-bottom: 0.75rem;
}

.highlight h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    border-radius: 3px;
    transition: width 0.3s ease;
}

.highlight:hover h3:after {
    width: 60px;
}

.subsection-heading {
    font-size: clamp(1.3rem, 3vw, 1.6rem);
    color: var(--primary-color);
    margin: 0 0 2rem;
    position: relative;
    display: inline-block;
}

.subsection-heading::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 25%;
    width: 50%;
    height: 2px;
    background: var(--accent-color);
    border-radius: 2px;
}

.audience-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
    text-align: left;
}

.audience-card {
    background: linear-gradient(135deg, rgba(var(--primary-rgb), 0.02), rgba(var(--secondary-rgb), 0.04));
    padding: 2rem;
    border-radius: 16px;
    transition: all 0.3s ease;
    border: 1px solid rgba(var(--primary-rgb), 0.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
    position: relative;
    overflow: hidden;
}

.audience-card:hover {
    background: linear-gradient(135deg, rgba(var(--primary-rgb), 0.04), rgba(var(--secondary-rgb), 0.08));
    transform: translateY(-8px);
    border-color: var(--accent-color);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
}

.audience-card::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, var(--accent-color), var(--accent-light));
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.audience-card:hover::before {
    transform: scaleX(1);
}

.audience-card h4 {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    color: var(--primary-color);
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 0.5rem;
    display: inline-block;
}

.audience-card h4::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 2px;
    background: var(--accent-color);
    transition: width 0.3s ease;
}

.audience-card:hover h4::after {
    width: 50px;
}

/* Government Agencies styling enhancement */
.audience-card:nth-child(3) {
    background: linear-gradient(135deg, rgba(var(--primary-rgb), 0.04), rgba(var(--primary-rgb), 0.08));
    border-left: 3px solid var(--accent-color);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.07);
}

.audience-card:nth-child(3):hover {
    background: linear-gradient(145deg, rgba(var(--primary-rgb), 0.06), rgba(var(--primary-rgb), 0.12));
    transform: translateY(-10px);
    box-shadow: 0 18px 40px rgba(0, 0, 0, 0.12);
}

.audience-card:nth-child(3)::before {
    background: linear-gradient(to right, var(--accent-color), var(--primary-color));
    height: 5px;
}

.audience-card:nth-child(3) h4 {
    color: var(--primary-color);
    font-weight: 600;
}

.audience-card:nth-child(3) h4::after {
    width: 40px;
    background: linear-gradient(to right, var(--accent-color), var(--primary-color));
}

.audience-card:nth-child(3):hover h4::after {
    width: 70px;
}

@media (max-width: 768px) {
    .credential-badge {
        flex-direction: column;
        text-align: center;
        padding: 1.8rem 1.2rem;
    }
    
    .badge-icon {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    
    .about-grid,
    .audience-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}

/* Founder Section */
.founder-section {
    margin: 3rem 0;
}

.founder-card {
    display: flex;
    align-items: center;
    gap: 2rem;
    background: linear-gradient(135deg, rgba(var(--primary-rgb), 0.03), rgba(var(--primary-rgb), 0.08));
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.founder-card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: linear-gradient(to bottom, var(--primary-color), var(--accent-color));
}

.founder-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.founder-image {
    flex: 0 0 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
}

.founder-details {
    flex: 1;
}

.founder-details h4 {
    font-size: 1.5rem;
    color: var(--heading-color);
    margin-bottom: 0.5rem;
    position: relative;
    display: inline-block;
}

.founder-details h4::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40px;
    height: 3px;
    background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    transition: width 0.3s ease;
}

.founder-card:hover .founder-details h4::after {
    width: 60px;
}

.founder-title {
    font-size: 1rem;
    color: var(--accent-color);
    margin-bottom: 1rem;
    font-weight: 500;
}

.founder-bio {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-color);
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .founder-card {
        flex-direction: column;
        text-align: center;
        padding: 2rem 1.5rem;
    }
    
    .founder-card::before {
        width: 100%;
        height: 4px;
        top: 0;
        left: 0;
        background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    }
    
    .founder-image {
        margin-bottom: 1.5rem;
    }
    
    .founder-details h4::after {
        left: 50%;
        transform: translateX(-50%);
    }
}

.founder-cta {
    background: linear-gradient(145deg, rgba(var(--primary-rgb), 0.05), rgba(var(--primary-rgb), 0.1));
    border-radius: 16px;
    padding: 2rem;
    margin: 3rem 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.founder-cta:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.founder-cta h3 {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-weight: 600;
}

.founder-cta p {
    font-size: 1.1rem;
    color: var(--text-medium);
    max-width: 800px;
    margin: 0 auto 1.5rem;
    line-height: 1.6;
}

.founder-link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
    color: white;
    border-radius: 50px;
    font-weight: 600;
    text-decoration: none;
    box-shadow: 0 8px 20px rgba(var(--primary-rgb), 0.2);
    transition: all 0.3s ease;
}

.founder-link:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(var(--primary-rgb), 0.3);
}

.founder-link svg {
    transition: transform 0.3s ease;
}

.founder-link:hover svg {
    transform: translateX(5px);
}

@media (max-width: 768px) {
    .founder-cta {
        padding: 1.5rem;
        margin: 2rem 0;
    }
    
    .founder-cta h3 {
        font-size: 1.5rem;
    }
    
    .founder-cta p {
        font-size: 1rem;
    }
}