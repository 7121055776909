/* Additional styles for .get-started section integrated with app.css */
.get-started-container {
    background-color: var(--background-color);
    padding: 4rem 2rem;
    text-align: center;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Hero section with dark blue background */
.get-started-hero {
    background: #0a1f44;
    color: white;
    padding: 5rem 2rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
}

.get-started-hero-content {
    position: relative;
    z-index: 1;
    max-width: 800px;
    margin: 0 auto;
}

.get-started-hero h1 {
    font-size: clamp(2.5rem, 5vw, 4rem);
    font-weight: 700;
    margin-bottom: 1.5rem;
    letter-spacing: -0.5px;
    color: white;
}

.get-started-hero p {
    font-size: clamp(1.1rem, 2vw, 1.5rem);
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
    color: var(--accent-color, #ffd700);
}

/* Main content layout */
.get-started-content {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    gap: 4rem;
}

.get-started-card {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 16px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
    padding: 3rem 2.5rem;
    max-width: 700px;
    width: 100%;
    margin: 2rem auto 140px;
    text-align: center;
    border: 1px solid rgba(var(--primary-rgb), 0.08);
    position: relative;
    overflow: hidden;
}

.get-started-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(var(--primary-rgb), 0.03) 0%, rgba(var(--accent-rgb), 0.03) 100%);
    z-index: 0;
}

.get-started-icon {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 100%;
    color: var(--accent-color);
    margin-bottom: 1.5rem;
    transition: all 0.4s ease-in-out;
    font-size: 3.5rem;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
}

.get-started-icon:hover {
    transform: rotate(360deg) scale(1.1);
    color: var(--primary-color);
}

.get-started-title {
    position: relative;
    z-index: 1;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
    font-size: clamp(1.8rem, 4vw, 2.2rem);
    font-weight: 700;
}

.get-started-text {
    position: relative;
    z-index: 1;
    color: var(--text-medium);
    margin-bottom: 2.5rem;
    font-size: clamp(1rem, 2vw, 1.1rem);
    line-height: 1.6;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.submission-confirmation {
    position: relative;
    z-index: 1;
    color: var(--text-medium);
    font-size: clamp(1rem, 2vw, 1.1rem);
    line-height: 1.6;
    padding: 1.5rem;
    background: rgba(var(--accent-rgb), 0.1);
    border-radius: 12px;
    margin-top: 1.5rem;
}

/* Form styling */
.get-started-form-container {
    flex: 1.2;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    position: relative;
}

.get-started-info {
    flex: 1;
    padding: 0 1rem;
}

.info-section {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 16px;
    padding: 2.5rem;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.get-started-form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 550px;
    width: 100%;
    margin: auto;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}

.form-header {
    text-align: center;
    margin-bottom: 2.5rem;
}

.form-icon {
    color: var(--primary-color);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.get-started-form input,
.get-started-form textarea,
.get-started-form select {
    padding: 1rem 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid rgba(var(--primary-rgb), 0.15);
    border-radius: 12px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
    color: var(--text-dark);
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.03);
}

.get-started-form textarea {
    min-height: 120px;
    resize: vertical;
}

.get-started-form input:focus,
.get-started-form textarea:focus,
.get-started-form select:focus {
    outline: none;
    border-color: var(--accent-color);
    background-color: #fff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.07);
    transform: translateY(-2px);
}

.get-started-form input::placeholder,
.get-started-form textarea::placeholder {
    color: rgba(var(--text-dark-rgb), 0.5);
}

.form-group label {
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--text-dark);
    text-align: left;
}

.required {
    color: var(--accent-color);
}

.form-privacy {
    font-size: 0.85rem;
    color: var(--text-medium);
    text-align: center;
    margin-bottom: 1rem;
}

.form-privacy a {
    color: var(--primary-color);
    text-decoration: none;
    transition: all 0.3s ease;
}

.form-privacy a:hover {
    text-decoration: underline;
}

/* Button styles */
.get-started-form button.submit-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 0;
    padding: 1rem 2.5rem;
    border-radius: 50px;
    border: none;
    background: linear-gradient(45deg, var(--primary-color), var(--accent-color));
    color: var(--text-light);
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 20px rgba(var(--primary-rgb), 0.2);
    gap: 0.5rem;
}

.submitting-request {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 0;
    padding: 1rem 2.5rem;
    border-radius: 50px;
    border: none;
    background: linear-gradient(45deg, var(--primary-color), var(--accent-color));
    color: var(--text-light);
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.5px;
    cursor: not-allowed;
    opacity: 0.8;
    transition: all 0.3s ease;
    box-shadow: 0 8px 20px rgba(var(--primary-rgb), 0.2);
    gap: 0.5rem;
}

.get-started-form button.submit-btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(var(--primary-rgb), 0.3);
    background: linear-gradient(45deg, var(--accent-color), var(--primary-color));
}

/* Service items styling */
.services-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.service-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    text-align: left;
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

.service-icon {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.service-item h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-dark);
}

.service-item p {
    font-size: 0.95rem;
    color: var(--text-medium);
    line-height: 1.5;
}

/* Contact options */
.contact-options {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    text-align: left;
}

.contact-options h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
    color: var(--text-dark);
}

.contact-option {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.contact-icon {
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-right: 0.8rem;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    color: var(--primary-color);
    font-size: 1.2rem;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.social-links a:hover {
    background: var(--primary-color);
    color: white;
    transform: translateY(-3px);
}

/* Success message */
.success-message {
    text-align: center;
    padding: 2rem;
}

.success-icon {
    font-size: 4rem;
    color: #28a745;
    margin-bottom: 1.5rem;
}

.success-message h2 {
    font-size: 2.5rem;
    color: var(--text-dark);
    margin-bottom: 1rem;
}

.success-message p {
    font-size: 1.1rem;
    color: var(--text-medium);
    max-width: 500px;
    margin: 0 auto 2rem;
    line-height: 1.6;
}

.next-steps {
    background: rgba(40, 167, 69, 0.08);
    border-radius: 12px;
    padding: 1.5rem;
    margin: 2rem 0;
    text-align: left;
}

.next-steps h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: var(--text-dark);
}

.next-steps ul {
    list-style: none;
    padding: 0;
}

.next-steps li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.8rem;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
}

.next-steps li svg {
    color: #28a745;
    font-size: 1.2rem;
    margin-right: 1rem;
}

.additional-questions {
    margin-top: 2.5rem;
}

.additional-questions p {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.contact-email {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background: var(--primary-light, #4d9bff);
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.contact-email:hover {
    background: var(--primary-color);
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .get-started-content {
        padding: 2rem 1.5rem;
        gap: 3rem;
    }
}

@media (max-width: 992px) {
    .get-started-content {
        flex-direction: column;
        padding: 2rem 1.5rem;
        gap: 2.5rem;
    }
    
    .get-started-info,
    .get-started-form-container {
        width: 100%;
    }
    
    .services-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .get-started-container {
        padding: 3rem 1.5rem;
    }

    .get-started-hero {
        padding: 4rem 1.5rem;
    }
    
    .get-started-form-container {
        padding: 2.5rem 1.5rem;
    }
    
    .services-list {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .form-row {
        grid-template-columns: 1fr;
    }

    .get-started-card {
        padding: 2.5rem 1.5rem;
    }
    
    .get-started-title {
        margin-bottom: 1rem;
    }
    
    .get-started-text {
        margin-bottom: 2rem;
        max-width: 100%;
    }

    .get-started-form {
        max-width: 100%;
    }

    .get-started-icon {
        font-size: 3rem;
    }
}

@media (max-width: 576px) {
    .get-started-hero {
        padding: 3rem 1rem;
    }
    
    .get-started-content {
        padding: 1.5rem 1rem;
    }
    
    .info-section,
    .get-started-form-container {
        padding: 1.5rem;
    }
    
    .services-list {
        grid-template-columns: 1fr;
    }

    .get-started-card {
        margin: 1rem auto 100px;
        padding: 2rem 1.25rem;
        border-radius: 12px;
    }

    .get-started-icon {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    
    .get-started-form button.submit-btn,
    .submitting-request {
        width: 100%;
        padding: 0.9rem 1.5rem;
    }
    
    .get-started-form input,
    .get-started-form textarea,
    .get-started-form select {
        padding: 0.8rem;
        margin-bottom: 1rem;
        border-radius: 8px;
    }
    
    .submission-confirmation {
        padding: 1rem;
    }
}