:root {
  --navbar-height: 80px;
  --navbar-scrolled-height: 70px;
  --navbar-background: rgba(8, 29, 54, 0.85);
  --navbar-scrolled-background: rgba(10, 46, 82, 0.95);
  --navbar-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  --navbar-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --navbar-link-color: var(--text-light);
  --navbar-link-hover-color: var(--accent-color);
  --navbar-cta-background: var(--accent-color);
  --navbar-cta-hover-background: var(--accent-dark);
  --navbar-cta-color: var(--primary-dark);
  --dropdown-background: rgba(8, 29, 54, 0.97);
  --dropdown-border: rgba(255, 255, 255, 0.05);
  --dropdown-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  --dropdown-item-hover: rgba(255, 255, 255, 0.08);
  --divider-color: rgba(255, 255, 255, 0.08);
  --dropdown-header-color: rgba(255, 255, 255, 0.6);
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--navbar-background);
  box-shadow: var(--navbar-shadow);
  z-index: 1000;
  transition: var(--navbar-transition);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.navbar.scrolled {
  height: var(--navbar-scrolled-height);
  background-color: var(--navbar-scrolled-background);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
}

.navbar-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  display: inline-flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  z-index: 1001;
  transition: var(--navbar-transition);
}

.navbar-logo img {
  height: 50px;
  width: auto;
  transition: var(--navbar-transition);
  transform-origin: left center;
}

.navbar.scrolled .navbar-logo img {
  height: 42px;
  transform: scale(0.95);
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin-left: 1.5rem;
}

.nav-link {
  color: var(--navbar-link-color);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 4px;
  transition: all 0.3s ease;
  position: relative;
  letter-spacing: 0.02em;
}

.nav-link:hover {
  color: var(--navbar-link-hover-color);
  transform: translateY(-2px);
}

.nav-link::after {
  display: none;
}

.nav-link:hover::after,
.nav-link.active::after {
  display: none;
}

/* Ensure no underlines are applied to any links */
.navbar a, 
.navbar-links a,
.navbar-menu a {
  text-decoration: none;
}

/* Specific override for Capability Statement and About Us links */
.navbar-links a[href="/capability-statement"],
.navbar-links a[href="/founder"] {
  text-decoration: none;
}

.navbar-links a[href="/capability-statement"]::after,
.navbar-links a[href="/founder"]::after {
  display: none;
}

.navbar-links a[href="/capability-statement"]:hover::after,
.navbar-links a[href="/founder"]:hover::after {
  display: none;
}

.nav-link.cta-button {
  background-color: var(--navbar-cta-background);
  color: var(--navbar-cta-color);
  padding: 10px 24px;
  border-radius: 50px;
  border: 2px solid var(--navbar-cta-background);
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 4px 12px rgba(255, 188, 66, 0.2);
}

.nav-link.cta-button:hover {
  background-color: var(--navbar-cta-hover-background);
  border-color: var(--navbar-cta-hover-background);
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(255, 188, 66, 0.25);
}

.nav-link.cta-button::after {
  display: none;
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--navbar-link-color);
  transition: color 0.3s ease;
  letter-spacing: 0.02em;
}

.dropdown-toggle:hover {
  color: var(--navbar-link-hover-color);
  transform: translateY(-2px);
}

.dropdown-icon {
  transition: transform 0.3s ease, color 0.3s ease;
  color: var(--accent-color);
  font-size: 0.7rem;
  margin-left: 2px;
}

.dropdown-toggle:hover .dropdown-icon {
  color: var(--navbar-link-hover-color);
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle:hover::after,
.dropdown-toggle[aria-expanded="true"]::after {
  display: none;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  min-width: 240px;
  background-color: var(--dropdown-background);
  border-radius: 12px;
  border: 1px solid var(--dropdown-border);
  box-shadow: var(--dropdown-shadow);
  padding: 1rem 0;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  pointer-events: none;
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  pointer-events: auto;
}

.dropdown-item {
  display: block;
  padding: 10px 20px;
  color: var(--navbar-link-color);
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 15px;
  position: relative;
}

.dropdown-item:hover {
  background-color: var(--dropdown-item-hover);
  color: var(--navbar-link-hover-color);
  padding-left: 24px;
}

.dropdown-divider {
  height: 1px;
  margin: 0.8rem 1rem;
  background-color: var(--divider-color);
}

.dropdown-header {
  display: block;
  padding: 10px 20px;
  font-size: 12px;
  color: var(--dropdown-header-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
}

/* Mobile menu trigger */
.navbar-mobile-trigger {
  display: none;
  cursor: pointer;
  font-size: 20px;
  z-index: 1001;
  color: var(--navbar-link-color);
  background: transparent;
  border: none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: none;
  align-items: center;
  justify-content: center;
}

.navbar-mobile-trigger:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--navbar-link-hover-color);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .navbar-mobile-trigger {
    display: flex;
  }
  
  .navbar-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 320px;
    height: 100vh;
    background-color: var(--navbar-background);
    box-shadow: -5px 0 25px rgba(0, 0, 0, 0.25);
    transition: right 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    display: block;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding-top: calc(var(--navbar-height) + 1rem);
    overflow-y: auto;
  }
  
  .navbar-menu.active {
    right: 0;
  }
  
  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0 2rem;
  }
  
  .dropdown {
    width: 100%;
  }
  
  .dropdown-toggle {
    width: 100%;
    justify-content: space-between;
    padding: 12px 0;
  }
  
  .dropdown-menu {
    position: static;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    transform: translateY(0);
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  
  .dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
  }
  
  .dropdown-item {
    padding: 12px 16px;
    padding-left: 1rem;
    position: relative;
  }
  
  .dropdown-item:hover {
    padding-left: 1.5rem;
  }
  
  .dropdown-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 1px;
    background-color: var(--navbar-link-hover-color);
    transition: width 0.2s ease;
    transform: translateY(-50%);
  }
  
  .dropdown-item:hover::before {
    width: 0.5rem;
  }
  
  .nav-link.cta-button {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }

  .nav-link {
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .nav-link::after {
    display: none;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.navbar-cta-button {
  background-color: var(--primary-color);
  color: white !important;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.navbar-cta-button:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
