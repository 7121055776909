/* PDF-optimized Capability Statement CSS */
.cs-pdf-container {
  width: 8.5in;
  height: 11in;
  padding: 0.35in;
  background-color: white;
  color: #131517; /* Hardcoded colors for PDF reliability */
  font-family: Arial, Helvetica, sans-serif; /* Web-safe fonts for PDF */
  font-size: 9pt;
  position: relative;
  box-sizing: border-box;
  line-height: 1.25;
  overflow: hidden;
}

/* Header section */
.cs-pdf-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.1in;
  padding-bottom: 0.08in;
  border-bottom: 2px solid #e8c883; /* Hardcoded accent color */
}

/* Company name styling (replacing logo) */
.cs-pdf-company-name {
  width: 2.5in;
  display: flex;
  align-items: center;
}

.cs-pdf-company-name h2 {
  font-family: Arial, Helvetica, sans-serif;
  color: #0a2e52; /* Hardcoded primary color */
  font-size: 14pt;
  font-weight: bold;
  margin: 0;
  padding: 0;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  border-bottom: none;
}

.cs-pdf-title {
  flex: 1;
  text-align: right;
}

.cs-pdf-title h1 {
  font-family: Arial, Helvetica, sans-serif;
  color: #0a2e52; /* Hardcoded primary color */
  font-size: 22pt;
  margin: 0 0 0.05in 0;
  letter-spacing: -0.5px;
  font-weight: bold;
  text-transform: uppercase;
}

.cs-pdf-title p {
  color: #1e4a8a; /* Hardcoded secondary color */
  font-size: 10pt;
  margin: 0;
  font-weight: 500;
}

/* Overview section */
.cs-pdf-overview {
  margin-bottom: 0.1in;
  border-bottom: 1px solid #e8c883; /* Hardcoded accent color */
  padding-bottom: 0.08in;
}

.cs-pdf-overview p {
  margin: 0;
  font-size: 9pt;
  line-height: 1.3;
  color: #333333;
}

/* Content layout */
.cs-pdf-content {
  display: flex;
  gap: 0.2in;
  height: 8.6in; /* Adjusted height for content */
}

.cs-pdf-column {
  display: flex;
  flex-direction: column;
}

.cs-pdf-left {
  width: 3.2in;
}

.cs-pdf-right {
  flex: 1;
}

/* Section styling */
.cs-pdf-section {
  margin-bottom: 0.15in;
  padding-bottom: 0.05in;
  border-bottom: 1px solid #f0f0f0; /* Light separator between sections */
}

.cs-pdf-section:last-child {
  border-bottom: none;
}

.cs-pdf-section h2 {
  font-size: 12pt;
  margin-bottom: 0.08in;
  color: #0a4c9e;
  padding-bottom: 0.03in;
  border-bottom: 1px solid #e8c883; /* Accent color underline */
}

.cs-pdf-section h3 {
  font-family: Arial, Helvetica, sans-serif;
  color: #0a2e52; /* Hardcoded primary color */
  font-size: 9pt;
  margin: 0.05in 0 0.03in 0;
  font-weight: bold;
}

/* Tables */
.cs-pdf-info-table, 
.cs-pdf-naics-table {
  width: 100%;
  border-collapse: collapse;
}

.cs-pdf-info-table td,
.cs-pdf-naics-table td {
  padding: 2pt 2pt;
  vertical-align: top;
}

.cs-pdf-info-table td:first-child {
  width: 40%;
  font-weight: 600;
  color: #3e4e6c; /* Hardcoded medium text color */
}

.cs-pdf-naics-code {
  width: 60px;
  font-weight: 600;
  color: #0a2e52; /* Hardcoded primary color */
}

/* Core Capabilities */
.cs-pdf-capability-item {
  margin-bottom: 0.15in;
}

.cs-pdf-capability-item:last-child {
  margin-bottom: 0;
}

.cs-pdf-capability-list {
  margin: 0.05in 0 0.1in 0.15in;
  padding-left: 0.1in;
  font-size: 9.5pt;
  list-style-type: disc;
}

.cs-pdf-capability-list li {
  margin-bottom: 0.06in;
  line-height: 1.3;
  padding-left: 0;
  display: list-item;
}

/* Capability item title */
.cs-pdf-capability-item h3 {
  font-size: 11pt;
  margin-bottom: 0.05in;
  color: #0a4c9e;
}

/* Differentiators */
.cs-pdf-differentiators {
  display: flex;
  flex-direction: column;
  gap: 4pt;
}

.cs-pdf-diff-item {
  margin-bottom: 3pt;
}

.cs-pdf-diff-title {
  margin: 0 0 1pt 0;
  color: #0a2e52; /* Hardcoded primary color */
  font-size: 9pt;
}

.cs-pdf-diff-desc {
  margin: 0;
  font-size: 8pt;
  line-height: 1.2;
}

/* Lists */
.cs-pdf-cert-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0.03in 0;
}

.cs-pdf-cert-list li {
  position: relative;
  padding-left: 12pt;
  margin-bottom: 3pt;
  line-height: 1.2;
  font-size: 8.5pt;
}

.cs-pdf-cert-list li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #e8c883; /* Hardcoded accent color */
  font-weight: bold;
}

/* Contact section */
.cs-pdf-contact {
  border-bottom: none;
}

.cs-pdf-contact-info {
  line-height: 1.3;
  font-size: 8.5pt;
}

/* Performance section */
.cs-pdf-performance {
  display: flex;
  flex-direction: column;
  gap: 6pt;
}

.cs-pdf-performance-item {
  margin-bottom: 0.1in;
}

.cs-pdf-performance-item:last-child {
  margin-bottom: 0;
}

.cs-pdf-perf-client {
  font-size: 9.5pt;
  margin-bottom: 0.03in;
}

.cs-pdf-perf-desc {
  font-size: 8.5pt;
  line-height: 1.3;
}

/* Footer */
.cs-pdf-footer {
  position: absolute;
  bottom: 0.25in;
  left: 0.35in;
  right: 0.35in;
  text-align: center;
  border-top: 1px solid #e8c883; /* Hardcoded accent color */
  padding-top: 0.08in;
}

.cs-pdf-footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2pt;
}

.cs-pdf-footer p {
  margin: 0;
  font-size: 7.5pt;
  color: #3e4e6c; /* Hardcoded medium text color */
}

.cs-pdf-footer-tag {
  font-weight: 600;
  color: #0a2e52 !important; /* Hardcoded primary color */
}

/* Font optimization for print */
.cs-pdf-container strong {
  font-weight: 600;
}

.cs-pdf-container p, 
.cs-pdf-container li,
.cs-pdf-container td {
  font-weight: 400;
}

/* Print-specific optimizations */
@media print {
  body {
    margin: 0;
    padding: 0;
  }
  
  .cs-pdf-container {
    width: 100%;
    height: 100%;
    padding: 0.35in;
    margin: 0;
    page-break-after: always;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
} 