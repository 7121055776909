/* 404 Not Found Page Styling */
.notfound-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 2rem;
  background-color: var(--background-light);
}

.notfound-content {
  max-width: 600px;
  text-align: center;
  padding: 3rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  animation: fadeIn 0.8s ease-in-out;
}

.notfound-icon {
  font-size: 5rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.notfound-content h1 {
  font-size: 3.5rem;
  color: var(--primary-color);
  margin: 0 0 0.5rem 0;
  font-weight: 700;
}

.notfound-content h2 {
  font-size: 1.8rem;
  color: var(--text-dark);
  margin: 0 0 1.5rem 0;
  font-weight: 600;
}

.notfound-content p {
  font-size: 1.1rem;
  color: var(--text-medium);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.notfound-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.notfound-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  min-width: 200px;
}

.notfound-btn.primary-btn {
  background-color: var(--primary-color);
  color: white;
}

.notfound-btn.primary-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.notfound-btn.secondary-btn {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.notfound-btn.secondary-btn:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries for responsive design */
@media (min-width: 768px) {
  .notfound-actions {
    flex-direction: row;
    justify-content: center;
  }
  
  .notfound-btn {
    min-width: 180px;
  }
}

@media (max-width: 767px) {
  .notfound-content {
    padding: 2rem;
  }
  
  .notfound-content h1 {
    font-size: 3rem;
  }
  
  .notfound-content h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .notfound-container {
    padding: 1rem;
  }
  
  .notfound-content {
    padding: 1.5rem;
  }
  
  .notfound-content h1 {
    font-size: 2.5rem;
  }
  
  .notfound-icon {
    font-size: 4rem;
  }
} 