/* General Footer Styles */
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--background-color);
    background-color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 var(--soft-white-shadow);
    margin: 0;
    padding: .10rem 0; /* Added padding for consistency */
}

/* Company Logo */
.footer-logo {
    display: flex;
    align-items: center; /* Align vertically */
    padding: .1rem; /* Simplified padding */
}

.footer-logo img {
    width: 50px; /* Set your desired width */
    height: auto; /* Keep the aspect ratio of the image */
}

/* Social Icons, Service Links, and Contact Styles */
.footer-socials,
.footer-service-link,
.footer-contact {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
    justify-content: center; /* Center-align for better mobile appearance */
    gap: .5rem;
    padding: .1rem;
}

/* Bottom Row Styles */
.footer-bottom {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--background-color);
}

/* Consistent font size for all elements in the footer */
.footer-bottom a,
.footer-bottom p,
.footer-copy,
.footer-legal {
    font-size: .9em; /* Adjusted for consistency */
    text-align: center; /* Center-align text for mobile screens */
    color: var(--background-color);
}


/* Link Styles */
footer a {
    color: var(--background-color);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

/* Adjusts the hover effect for links */
footer a:hover {
    color: var(--primary-color);
}

/* Responsive adjustments for Tablets */
@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .footer-logo img {
        width: 50px;
    }
}

/* Responsive adjustments for Mobile Devices */
@media (max-width: 480px) {
    .footer-grid {
        grid-template-columns: 1fr;
    }
    footer {
        padding: 1rem;
    }
    .footer-bottom {
        flex-direction: column;
    }
}

/* Modern Footer Styling */
.footer-container {
    position: relative;
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-color) 100%);
    color: var(--text-light);
    padding: 0;
    margin-top: 3rem;
    overflow: hidden;
}

/* Footer wave divider */
.footer-wave {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 1;
}

.footer-wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
    filter: drop-shadow(0 -5px 10px rgba(0, 0, 0, 0.1));
}

.footer-wave .shape-fill {
    fill: var(--background-color);
}

/* Footer content container */
.footer-content {
    position: relative;
    z-index: 1;
    max-width: 1100px;
    margin: 0 auto;
    padding: 4rem 2rem 2rem;
}

/* Main footer area with company info and links */
.footer-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3rem;
    margin-bottom: 3rem;
}

/* Company branding section */
.footer-branding {
    flex: 1;
    min-width: 250px;
    max-width: 350px;
}

.footer-branding h3 {
    font-size: 1.6rem;
    margin: 1.2rem 0 0.75rem;
    color: var(--text-light);
    font-weight: 600;
    background: linear-gradient(135deg, #ffffff, rgba(255, 255, 255, 0.8));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.footer-branding p {
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.6;
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
}

.footer-logo {
    width: 65px;
    height: auto;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.2));
    transition: transform 0.3s ease;
}

.footer-logo:hover {
    transform: scale(1.05);
}

/* Footer links section */
.footer-links {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2.5rem;
}

.footer-links-column {
    min-width: 160px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer-links-column h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: var(--accent-color);
    position: relative;
    display: inline-block;
    padding-bottom: 0.5rem;
    font-weight: 600;
}

.footer-links-column h4::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background: linear-gradient(to right, var(--accent-color), rgba(255, 255, 255, 0.5));
    border-radius: 3px;
    transition: width 0.3s ease;
}

.footer-links-column:hover h4::after {
    width: 60px;
}

.footer-links-column a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 0.95rem;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    padding: 0.3rem 0;
}

.footer-links-column a::after {
    display: none;
}

.footer-links-column a:hover {
    color: var(--accent-color);
    transform: translateX(5px);
}

.footer-links-column a:hover::after {
    display: none;
}

/* Social media icons */
.footer-social-icons {
    display: flex;
    gap: 1rem;
    margin-top: 0.75rem;
}

.footer-social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    color: var(--text-light);
    font-size: 1.1rem;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-social-icons a:hover {
    background: var(--accent-color);
    color: var(--primary-dark);
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

/* Footer bottom section with copyright and legal links */
.footer-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    gap: 1.5rem;
}

.footer-copy p {
    margin: 0;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
}

.footer-legal {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-legal a {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    position: relative;
}

.footer-legal a::after {
    display: none;
}

.footer-legal a:hover {
    color: var(--accent-color);
}

.footer-legal a:hover::after {
    display: none;
}

.separator {
    color: rgba(255, 255, 255, 0.4);
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .footer-branding {
        max-width: 100%;
        text-align: center;
        margin-bottom: 1rem;
    }
    
    .footer-main {
        flex-direction: column;
        gap: 2rem;
    }
    
    .footer-links {
        justify-content: space-around;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .footer-wave svg {
        height: 50px;
    }
    
    .footer-links {
        gap: 1.5rem;
    }
    
    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }
    
    .footer-content {
        padding: 3rem 1.5rem 1.5rem;
    }
}

@media (max-width: 576px) {
    .footer-links {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .footer-links-column {
        width: 100%;
        align-items: center;
    }
    
    .footer-links-column h4::after {
        left: 50%;
        transform: translateX(-50%);
    }
    
    .footer-logo {
        width: 55px;
    }
    
    .footer-social-icons {
        justify-content: center;
    }
}