/* Modern elegant intro section styling with animations */
.intro-section {
    position: relative;
    min-height: 90vh;
    width: 100%;
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-color) 100%);
    color: var(--text-light);
    overflow: hidden;
    padding-bottom: 0;
    margin-bottom: 0;
}

.intro-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.07'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.1;
    z-index: 0;
}

/* Content layout with flex for responsive design */
.intro-content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    gap: 2rem;
}

/* Text container with spacing and animations */
.intro-text-container {
    flex: 1;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;
}

/* Main heading styling with multiple text treatments */
.brand-heading {
    font-size: clamp(2.8rem, 6vw, 4rem);
    font-weight: 800;
    margin-bottom: 2rem;
    line-height: 1.2;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    color: var(--text-light);
    text-align: center;
}

.text-reveal {
    display: inline-block;
    opacity: 0;
    animation: fadeInUp 0.8s forwards;
    animation-delay: 0.2s;
    color: var(--text-light);
}

.gradient-text {
    background: linear-gradient(to right, var(--accent-color), var(--accent-light));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    opacity: 0;
    animation: fadeInUp 0.8s forwards;
    animation-delay: 0.4s;
    position: relative;
    text-shadow: none;
}

.gradient-text::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, var(--accent-color), var(--accent-light));
    transform: scaleX(0);
    transform-origin: left;
    animation: scaleIn 0.8s forwards;
    animation-delay: 1s;
    border-radius: 2px;
}

.technology-text {
    color: var(--text-light);
    opacity: 0;
    animation: fadeInUp 0.8s forwards;
    animation-delay: 0.6s;
}

/* Paragraph styling */
.intro-text {
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    line-height: 1.7;
    margin-bottom: 2.5rem;
    opacity: 0;
    animation: fadeInUp 0.8s forwards;
    animation-delay: 0.8s;
    color: var(--text-light);
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

/* Animated tags styling */
.animated-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 2rem;
    justify-content: center;
}

.tag-item {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50px;
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    color: var(--text-light);
    border: 1px solid rgba(255, 255, 255, 0.3);
    opacity: 0;
    transform: translateY(20px);
    animation: tagAnimation 0.6s forwards;
    transition: all 0.3s ease;
    font-weight: 500;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.tag-item:hover {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.tag-item:nth-child(1) {
    animation-delay: 1s;
}

.tag-item:nth-child(2) {
    animation-delay: 1.1s;
}

.tag-item:nth-child(3) {
    animation-delay: 1.2s;
}

.tag-item:nth-child(4) {
    animation-delay: 1.3s;
}

/* Call to action buttons */
.intro-cta-container {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    opacity: 0;
    animation: fadeInUp 0.8s forwards;
    animation-delay: 1.4s;
    justify-content: center;
}

.intro-cta-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    min-width: 160px;
    justify-content: center;
}

.intro-cta-button.primary {
    background-color: var(--accent-color);
    color: var(--primary-dark);
    border-color: var(--accent-color);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.intro-cta-button.primary:hover {
    background-color: var(--accent-light);
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.intro-cta-button.secondary {
    background-color: transparent;
    color: var(--text-light);
    border-color: var(--text-light);
}

.intro-cta-button.secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.cta-icon {
    font-size: 0.9rem;
    transition: transform 0.3s ease;
}

.intro-cta-button:hover .cta-icon {
    transform: translateX(5px);
}

/* Hero visual with floating elements */
.hero-visual {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
}

.floating-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    min-height: 350px;
}

.element-link {
    display: block;
    position: absolute;
    text-decoration: none;
    pointer-events: auto;
}

.element {
    position: relative;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: var(--accent-color);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    opacity: 0;
    animation: floatIn 1s forwards, bounceFloat 15s infinite linear;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.element:hover {
    transform: translateY(-8px) scale(1.05);
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--accent-light);
    animation-play-state: paused;
}

/* Anchor the element-link positions */
a.element-link:nth-child(1) {
    top: 10%;
    left: 15%;
}

a.element-link:nth-child(2) {
    top: 65%;
    right: 25%;
}

a.element-link:nth-child(3) {
    top: 20%;
    right: 15%;
}

a.element-link:nth-child(4) {
    bottom: 15%;
    left: 25%;
}

a.element-link:nth-child(5) {
    bottom: 40%;
    left: 10%;
}

/* Section divider with wave */
.intro-section .section-divider,
.intro-wave-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    overflow: hidden;
    line-height: 0;
    z-index: 5;
    transform: translateY(1px);
}

.intro-section .section-divider svg,
.intro-wave-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100%;
}

/* Remove this as we're setting the fill directly in the SVG */
.intro-section .section-divider path,
.intro-wave-divider path {
    /* fill: var(--background-light); */
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@keyframes tagAnimation {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes floatIn {
    from {
        opacity: 0;
        transform: scale(0.8) translateY(20px);
    }
    to {
        opacity: 0.9;
        transform: scale(1) translateY(0);
    }
}

/* Add pulse animation for floating elements */
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(232, 200, 131, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(232, 200, 131, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(232, 200, 131, 0);
    }
}

/* Bounce animation that keeps elements within bounds */
@keyframes bounceFloat {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    15% {
        transform: translate(30px, -30px) rotate(5deg);
    }
    30% {
        transform: translate(-20px, 40px) rotate(-3deg);
    }
    45% {
        transform: translate(30px, 20px) rotate(2deg);
    }
    60% {
        transform: translate(-30px, -20px) rotate(-5deg);
    }
    75% {
        transform: translate(20px, 40px) rotate(3deg);
    }
    90% {
        transform: translate(-10px, -40px) rotate(-2deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

.element-1 {
    width: 80px;
    height: 80px;
    animation: floatIn 0.8s forwards, bounceFloat 12s infinite linear, pulse 2s infinite;
    animation-delay: 0.5s, 0.8s, 2s;
}

.element-2 {
    width: 90px;
    height: 90px;
    animation: floatIn 0.8s forwards, bounceFloat 18s infinite linear, pulse 2s infinite;
    animation-delay: 0.7s, 0.9s, 3s;
}

.element-3 {
    width: 100px;
    height: 100px;
    animation: floatIn 0.8s forwards, bounceFloat 15s infinite linear, pulse 2s infinite;
    animation-delay: 0.9s, 1s, 3.5s;
}

.element-4 {
    width: 70px;
    height: 70px;
    animation: floatIn 0.8s forwards, bounceFloat 20s infinite linear, pulse 2s infinite;
    animation-delay: 1.1s, 1.2s, 4s;
}

.element-5 {
    width: 85px;
    height: 85px;
    animation: floatIn 0.8s forwards, bounceFloat 17s infinite linear, pulse 2s infinite;
    animation-delay: 1.3s, 1.4s, 4.5s;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
    .intro-content {
        padding: 3rem 2rem;
        gap: 1rem;
    }

    .intro-text-container {
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .hero-visual {
        position: absolute;
        height: 100%;
    }

    a.element-link:nth-child(1) {
        top: 5%;
        left: 10%;
    }
    
    a.element-link:nth-child(2) {
        top: 70%;
        right: 15%;
    }
    
    a.element-link:nth-child(3) {
        top: 15%;
        right: 10%;
    }
    
    a.element-link:nth-child(4) {
        bottom: 25%;
        left: 15%;
    }
    
    a.element-link:nth-child(5) {
        bottom: 45%;
        left: 5%;
    }
    
    .intro-section .section-divider,
    .intro-wave-divider {
        height: 80px;
    }
    
    .intro-section .section-divider svg,
    .intro-wave-divider svg {
        height: 100%;
    }

    .element-1, .element-2, .element-3, .element-4, .element-5 {
        transform: scale(0.9);
    }
}

@media screen and (max-width: 768px) {
    .brand-heading {
        font-size: clamp(2rem, 8vw, 2.5rem);
    }

    .intro-text {
        font-size: clamp(1rem, 4vw, 1.2rem);
        padding: 0 1rem;
    }

    .animated-tags {
        justify-content: center;
        gap: 0.5rem;
    }

    .tag-item {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .intro-cta-container {
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        margin: 2rem auto 0;
        gap: 1rem;
    }

    .intro-cta-button {
        width: 100%;
        justify-content: center;
    }

    .element {
        font-size: 1.5rem;
    }

    .element-1, .element-2, .element-3, .element-4, .element-5 {
        transform: scale(0.8);
    }
    
    a.element-link:nth-child(1) {
        top: 5%;
        left: 5%;
    }
    
    a.element-link:nth-child(2) {
        top: 75%;
        right: 10%;
    }
    
    a.element-link:nth-child(3) {
        top: 10%;
        right: 5%;
    }
    
    a.element-link:nth-child(4) {
        bottom: 20%;
        left: 8%;
    }
    
    a.element-link:nth-child(5) {
        bottom: 40%;
        left: 0%;
    }

    .intro-section .section-divider,
    .intro-wave-divider {
        height: 60px;
    }
    
    .intro-section .section-divider svg,
    .intro-wave-divider svg {
        height: 100%;
    }
}

@media screen and (max-width: 480px) {
    .intro-section {
        min-height: 85vh;
    }
    
    .brand-heading {
        font-size: clamp(1.8rem, 7vw, 2.2rem);
    }
    
    .intro-text {
        font-size: clamp(0.9rem, 4vw, 1.1rem);
    }
    
    .element {
        font-size: 1.2rem;
    }
    
    .element-1, .element-2, .element-3, .element-4, .element-5 {
        transform: scale(0.7);
    }

    .intro-section .section-divider,
    .intro-wave-divider {
        height: 40px;
    }
    
    .intro-section .section-divider svg,
    .intro-wave-divider svg {
        height: 100%;
    }

    .animated-tags {
        gap: 0.4rem;
    }
    
    .tag-item {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
    }
}

/* Respect user preferences for reduced motion */
@media (prefers-reduced-motion: reduce) {
    .text-reveal,
    .gradient-text,
    .technology-text,
    .intro-text,
    .tag-item,
    .intro-cta-container,
    .element {
        animation: none !important;
        opacity: 1 !important;
        transform: none !important;
    }
    
    .gradient-text::after {
        transform: scaleX(1) !important;
        animation: none !important;
    }
    
    .intro-cta-button:hover,
    .intro-cta-button:hover .cta-icon {
        transform: none !important;
    }
    
    @keyframes float {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(0);
        }
    }
}

.element-label {
    display: block;
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--text-light);
    opacity: 0;
    transform: translateY(-5px);
    transition: all 0.3s ease;
}

.element:hover .element-label {
    opacity: 1;
    transform: translateY(0);
}