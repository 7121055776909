:root {
  --service-card-bg: #fff;
  --service-card-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  --service-card-border: 1px solid rgba(0, 0, 0, 0.05);
  --service-card-radius: 12px;
  --service-card-padding: 2rem;
  --service-card-margin: 2rem 0;
  --service-icon-color: var(--primary-color);
  --service-icon-bg: rgba(var(--primary-rgb), 0.1);
  --service-icon-size: 5rem;
  --service-icon-padding: 1.5rem;
  --service-title-color: var(--main-heading-color);
  --service-text-color: var(--main-text-color);
  --service-list-bullet-color: var(--primary-color);
  --service-case-study-bg: rgba(var(--primary-rgb), 0.05);
  --service-case-study-border: 1px solid rgba(var(--primary-rgb), 0.2);
  --service-case-study-radius: 8px;
  --expand-toggle-color: var(--primary-color);
  --animation-duration: 0.6s;
  --animation-timing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --container-padding: 15px;
  --container-max-width: 1000px;
  --card-padding: 20px;
  --grid-gap: 20px;
  --icon-padding: 30px;
  --list-item-padding-left: 25px;
  --list-dot-size: 8px;
  --responsive-gap: clamp(15px, 2vw, 20px);
}

/* Value Proposition Section */
.value-proposition {
  background-color: var(--primary-color);
  color: white;
  padding: 5rem 0;
  text-align: center;
  margin-bottom: 3rem;
  background-image: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark) 100%);
  position: relative;
  overflow: hidden;
}

.value-proposition::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  opacity: 0.1;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.value-proposition .container {
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 var(--container-padding);
}

.value-proposition h1 {
  font-size: clamp(1.8rem, 4vw, 2.6rem);
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: white;
}

.company-credentials {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 1.5rem 0;
  flex-wrap: wrap;
}

.credential {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  padding: 0.5rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
}

.value-proposition p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.9);
}

/* Container Styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

/* Services Section */
#service-page-details {
  padding: 3rem 0 5rem;
}

/* Service Card Styles */
.service-page-card {
  background-color: var(--service-card-bg);
  border-radius: var(--service-card-radius);
  padding: var(--service-card-padding);
  margin: var(--service-card-margin);
  box-shadow: var(--service-card-shadow);
  border: var(--service-card-border);
  transition: box-shadow 0.3s ease;
  overflow: visible;
  min-height: 200px;
}

.service-page-card:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

.service-page-card.expanded {
  padding-bottom: 3rem;
}

/* Icon and Content Layout */
.icon-and-services {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.service-icon-container {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--primary-rgb), 0.1);
  border-radius: 50%;
  margin-top: 1rem;
}

.service-icon {
  color: var(--primary-color);
}

.service-content {
  flex: 1;
}

.service-content h2 {
  margin-top: 0;
  color: var(--main-heading-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.service-content p {
  color: var(--main-text-color);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

/* Expand toggle button styles */
.expand-toggle {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 600;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.3s ease;
}

.expand-toggle:hover {
  color: var(--secondary-color);
}

.expand-icon {
  transition: transform 0.3s ease;
}

.expand-icon.rotate {
  transform: rotate(180deg);
}

/* New expanded content styles */
.expanded-content {
  margin-top: 1.5rem;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Services list */
.services-list {
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
}

.services-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.services-list li:before {
  content: '•';
  color: var(--primary-color);
  position: absolute;
  left: 0;
  font-weight: bold;
}

/* Capability Statement Promo Section */
.capability-statement-promo {
  background: linear-gradient(135deg, rgba(var(--primary-rgb), 0.05), rgba(var(--primary-rgb), 0.1));
  padding: 4rem 2rem;
  border-radius: 16px;
  margin: 4rem 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
}

.capability-statement-promo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--accent-color), var(--primary-color));
}

.promo-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.promo-content h3 {
  color: var(--heading-color);
  font-size: clamp(1.8rem, 4vw, 2.5rem);
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.promo-content h3::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, var(--accent-color), var(--primary-color));
}

.promo-content p {
  color: var(--text-color);
  font-size: clamp(1rem, 2vw, 1.1rem);
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

.view-capability-btn {
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  color: white;
  font-weight: 600;
  padding: 0.8rem 2rem;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 15px rgba(var(--primary-rgb), 0.3);
}

.view-capability-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(var(--primary-rgb), 0.4);
}

.view-capability-btn svg {
  transition: transform 0.3s ease;
}

.view-capability-btn:hover svg {
  transform: translateX(4px);
}

/* Remove complex animation classes and ensure baseline functionality */
.animate-on-scroll {
  opacity: 1;
}

.animated {
  opacity: 1;
}

.fade-in, .fade-in-up {
  opacity: 1;
}

/* Simplify animations to be purely CSS-based without JS dependencies */
@media (prefers-reduced-motion: no-preference) {
  .service-page-card {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .service-page-card:nth-child(odd) {
    animation-delay: 0.1s;
  }
  
  .service-page-card:nth-child(even) {
    animation-delay: 0.2s;
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .value-proposition h1 {
    font-size: 2.2rem;
  }
  
  .value-proposition p {
    font-size: 1.1rem;
  }
  
  .promo-content {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  
  .promo-text, .promo-actions {
    flex: auto;
  }
}

@media (max-width: 768px) {
  .value-proposition {
    padding: 4rem 0;
  }
  
  .value-proposition h1 {
    font-size: 1.9rem;
  }
  
  .icon-and-services {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
  }
  
  .services-list li {
    text-align: left;
  }
  
  .service-page-card {
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .value-proposition h1 {
    font-size: 1.7rem;
  }
  
  .service-content h2 {
    font-size: 1.5rem;
  }
  
  .btn {
    width: 100%;
    padding: 0.8rem 1.5rem;
  }
}

/* Media queries */
@media (max-width: 480px) {
  :root {
    --container-padding: 10px;
    --card-padding: 15px;
    --icon-padding: 20px;
    --grid-gap: 15px;
    --font-size-title: 1.25rem;
    --font-size-text: 0.875rem;
  }

  .container, .service-page-card {
    padding: var(--container-padding);
  }

  .service-icon {
    padding: var(--icon-padding);
  }

  .icon-and-services {
    grid-template-columns: 1fr; /* Stack icon and content vertically on small screens */
    gap: var(--grid-gap);
  }

  h2 {
    font-size: var(--font-size-title);
  }

  p, .services-list li {
    font-size: var(--font-size-text);
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  :root {
    --container-padding: 15px;
    --card-padding: 18px;
    --icon-padding: 25px;
    --grid-gap: 18px;
    --font-size-title: 1.4rem;
    --font-size-text: 0.95rem;
  }

  .icon-and-services {
    gap: var(--grid-gap);
  }

  h2 {
    font-size: var(--font-size-title);
  }

  p, .services-list li {
    font-size: var(--font-size-text);
  }
}

@media (min-width: 769px) {
  /* Adjustments for larger screens can be placed here if needed,
     but may not be necessary if base styles are designed for desktop-first. */
}

.expand-toggle.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}