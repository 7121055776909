/* Founder component styling */
.founder-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--background-color, #f8f9fa);
    padding-bottom: 4rem;
}

.founder-hero {
    background: #0a1f44;
    color: white;
    text-align: center;
    padding: 5rem 2rem;
    margin-bottom: 3rem;
    position: relative;
}

.founder-hero h1 {
    font-size: clamp(2.5rem, 5vw, 4rem);
    font-weight: 700;
    margin-bottom: 1rem;
}

.founder-hero p {
    font-size: 1.25rem;
    max-width: 600px;
    margin: 0 auto;
    color: var(--accent-color, #ffd700);
}

.founder-card {
    display: flex;
    flex-direction: column;
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 16px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
    border: 1px solid rgba(var(--primary-rgb), 0.08);
    position: relative;
    overflow: hidden;
}

.founder-image {
    margin-bottom: 2rem;
    text-align: center;
}

.founder-image-placeholder {
    width: 200px;
    height: 200px;
    background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 6rem;
    color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.founder-details {
    flex: 1;
}

.founder-details h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    text-align: center;
}

.founder-title {
    font-size: 1.2rem;
    color: #0a4c9e;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 700;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    letter-spacing: 0.5px;
}

.founder-professional, .founder-vision, .founder-mission {
    margin-bottom: 2.5rem;
}

.founder-professional h3, .founder-vision h3, .founder-mission h3 {
    font-size: 1.5rem;
    color: var(--text-dark);
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 0.75rem;
}

.founder-professional h3:after, .founder-vision h3:after, .founder-mission h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: var(--primary-color);
    border-radius: 3px;
}

.founder-bio {
    font-size: 1.05rem;
    line-height: 1.7;
    color: var(--text-medium);
    margin-bottom: 1.25rem;
}

.founder-quote {
    background: rgba(var(--primary-rgb), 0.05);
    border-left: 4px solid var(--primary-color);
    padding: 1.5rem;
    margin: 2rem 0;
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-dark);
    font-style: italic;
    position: relative;
}

.founder-quote cite {
    display: block;
    margin-top: 1rem;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-color);
}

.founder-credentials {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: center;
}

.credential {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem;
    background: rgba(var(--primary-rgb), 0.07);
    border-radius: 50px;
    transition: all 0.3s ease;
}

.credential:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background: rgba(var(--primary-rgb), 0.1);
}

.credential svg {
    margin-right: 0.75rem;
    color: var(--primary-color);
    font-size: 1.2rem;
}

.credential span {
    font-weight: 500;
    color: var(--text-dark);
}

.founder-origin {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 1.5rem;
    position: relative;
}

.origin-flag, .origin-flower {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    padding: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
}

.origin-flag:hover, .origin-flower:hover {
    transform: translateY(-5px) rotate(5deg);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.usvi-flag {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hibiscus {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
}

.founder-personal {
    margin-bottom: 2.5rem;
}

.founder-personal h3 {
    font-size: 1.5rem;
    color: var(--text-dark);
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 0.75rem;
}

.founder-personal h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: var(--primary-color);
    border-radius: 3px;
}

@media (min-width: 992px) {
    .founder-card {
        flex-direction: row;
        padding: 4rem;
        gap: 3rem;
    }
    
    .founder-image {
        margin-bottom: 0;
    }
    
    .founder-details h2, .founder-title {
        text-align: left;
    }
    
    .founder-credentials {
        justify-content: flex-start;
    }
    
    .founder-origin {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .origin-flag, .origin-flower {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 768px) {
    .founder-hero {
        padding: 3.5rem 1.5rem;
    }
    
    .founder-card {
        padding: 2.5rem 1.5rem;
        width: 95%;
    }
    
    .founder-image-placeholder {
        width: 150px;
        height: 150px;
        font-size: 4.5rem;
    }
    
    .founder-credentials {
        flex-direction: column;
        align-items: center;
    }
    
    .credential {
        width: 100%;
        justify-content: center;
    }
    
    .founder-origin {
        gap: 1rem;
    }
    
    .origin-flag, .origin-flower {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 576px) {
    .founder-hero {
        padding: 3rem 1rem;
    }
    
    .founder-card {
        padding: 1.75rem 1.25rem;
        width: 100%;
        border-radius: 0;
    }
    
    .founder-details h2 {
        font-size: 2rem;
    }
    
    .founder-professional h3, .founder-vision h3, .founder-mission h3 {
        font-size: 1.3rem;
    }
    
    .founder-bio {
        font-size: 1rem;
    }
    
    .founder-quote {
        padding: 1.25rem;
        font-size: 1rem;
    }
    
    .founder-origin {
        gap: 1rem;
    }
    
    .origin-flag, .origin-flower {
        width: 50px;
        height: 50px;
    }
} 