/* Root CSS variables for theme consistency */
:root {
  /* Primary colors */
  --primary-color: #0a2e52; /* Deeper navy blue */
  --primary-dark: #081d36;
  --primary-light: #2c4975;
  --primary-rgb: 10, 46, 82;
  
  /* Secondary colors - replacing green with blue tones */
  --secondary-color: #1e4a8a; /* Secondary blue */
  --secondary-dark: #183c71;
  --secondary-light: #3563a0;
  --secondary-rgb: 30, 74, 138;
  
  /* Accent color */
  --accent-color: #e8c883; /* Lighter gold color */
  --accent-dark: #d8b56a;
  --accent-light: #f2dca6;
  --accent-rgb: 232, 200, 131;
  
  /* Text colors */
  --text-dark: #131517;
  --text-medium: #3e4e6c;
  --text-light: #ffffff;
  
  /* Background colors */
  --background-color: #ffffff;
  --background-light: #f8f9fc;
  --background-dark: #f1f3f8;
  
  /* Border colors */
  --border-color: #e2e5ef;
  
  --hover-accent-color: rgba(0, 168, 181, 0.08);
  --navbar-height: 60px;
  
  --darkened-primary-color: #004d7a;
  --darkened-secondary-color: #152d4a;
  --transparent-secondary-color: rgba(30, 58, 95, 0.9);
  
  --soft-dark-shadow: rgba(0, 0, 0, 0.1);
  --soft-white-shadow: rgba(255, 255, 255, 0.3);
  --soft-primary-shadow: rgba(0, 102, 162, 0.2);
  
  --base-font-size: 1rem;
  --heading-font-family: 'Montserrat', sans-serif;
  --body-font-family: 'Roboto', sans-serif;
  --heading-font-weight: 700;
  --base-line-height: 1.6;
}

/* Add these styles after the root variables */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  font-family: var(--body-font-family);
  line-height: var(--base-line-height);
  font-size: var(--base-font-size);
  color: var(--text-dark);
  background-color: var(--background-color);
  scroll-behavior: smooth;
}

main {
  display: block;
  width: 100%;
}

/* Section styling */
.section-wrapper {
  padding: 4rem 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

/* Base styles for body, focusing on font properties and background */
body {
  font-family: var(--body-font-family);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  background: var(--background-color);
  margin: 0;
  color: var(--main-text-color); /* Ensures text color consistency */
}

/* Centered App container with responsive font size */
.App {
  text-align: center;
  font-size: calc(1rem + 1vmin); /* Adjust font size based on viewport */
}

/* Dynamic logo sizing and rotation animation */
.App-logo {
  height: 20vmin;
  animation: App-logo-spin infinite 20s linear;
}

/* Header styling with flexible layout */
.App-header {
  background-color: var(--secondary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
}

/* Styling links with accent color */
.App-link {
  color: var(--accent-color);
  text-decoration: none; /* Remove underlines from links for clarity */
}

/* Enhancing heading elements with responsive fonts and dynamic underlines */
h2,
h3,
h4,
h5,
.brand-heading,
.intro .intro-body .intro-text {
  font-family: var(--heading-font-family);
  color: var(--primary-color);
  font-weight: var(--heading-font-weight);
}

h2 {
  font-size: clamp(2rem, 5vw, 3.5rem); /* Responsive font sizing */
}

h2:hover::after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: var(--primary-color);
  transition: all 0.2s ease-in-out;
}

.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Keyframe for logo spin animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media queries for adaptive layouts across different screen sizes */
@media only screen and (max-width: 480px) {
  .services-grid, .navbar-collapse {
    grid-template-columns: 1fr; /* Stack elements for mobile */
    display: flex; /* Ensure navbar is usable on mobile */
  }
}

@media (min-width: 481px) {
  .services-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adaptive grid layout */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Accessibility enhancements */
:focus {
  outline: 3px solid var(--accent-color); /* Highlight focus for keyboard navigation */
}

/* Ensure text remains legible for users with color vision deficiencies */
.has-text-warning {
  color: var(--soft-primary-shadow); /* Soft primary color for warnings */
}

/* Global section spacing and container rules */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;
}

section {
  padding: 4rem 0;
  position: relative;
}

section + section {
  margin-top: 1rem;
}

/* Section dividers and visual separation */
.section-divider {
  position: relative;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

.section-divider svg {
  width: 100%;
  height: 100%;
  display: block;
}

/* Section wrapper styles */
.section-wrapper {
  padding: 4rem 0;
  width: 100%;
}

.section-primary {
  background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-color) 100%);
  color: var(--text-light);
}

.section-light {
  background-color: var(--background-light);
  color: var(--text-dark);
}

.section-accent {
  background-color: var(--accent-light);
  color: var(--text-dark);
}

.section-dark {
  background-color: var(--primary-color);
  color: var(--text-light);
}

.section-secondary {
  background-color: var(--secondary-color);
  color: var(--text-light);
}

/* Featured section styles */
.featured-section {
  padding: 4rem 0;
  text-align: center;
}

.featured-section .section-heading {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: var(--text-light);
  margin-bottom: 1rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.featured-section .section-heading::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 30%;
  width: 40%;
  height: 3px;
  background: var(--accent-color);
  border-radius: 3px;
}

.featured-section .section-description {
  font-size: clamp(1rem, 2vw, 1.2rem);
  max-width: 700px;
  margin: 2rem auto;
  color: rgba(255, 255, 255, 0.8);
}

/* Featured cards */
.featured-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2.5rem 1.5rem;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  color: var(--text-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 280px;
}

.featured-card:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.featured-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, var(--accent-color), var(--accent-dark));
  border-radius: 50%;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: var(--primary-dark);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
}

.featured-card h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--text-light);
  position: relative;
  display: inline-block;
}

.featured-card h3::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: var(--accent-color);
}

.featured-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.6;
}

/* Service cards grid for the featured section */
.service-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2.5rem 0;
}

@media (max-width: 768px) {
  .featured-section {
    padding: 3rem 1rem;
  }
  
  .service-cards-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
}
