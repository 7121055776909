:root {
  --capability-primary: var(--primary-color);
  --capability-secondary: var(--secondary-color);
  --capability-accent: var(--accent-color);
  --capability-text: var(--text-dark);
  --capability-light: var(--background-light);
  --capability-dark: var(--text-dark);
  --capability-border: var(--border-color);
  --capability-shadow: var(--soft-dark-shadow);
  --capability-gradient-start: rgba(var(--primary-rgb), 0.8);
  --capability-gradient-end: rgba(var(--secondary-rgb), 0.9);
  --capability-card-hover: rgba(var(--accent-rgb), 0.1);
}

.capability-statement-container {
  color: var(--text-dark);
  font-family: var(--body-font-family, 'Roboto', sans-serif);
  line-height: var(--base-line-height, 1.6);
}

/* Hero Section */
.capability-hero {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--text-light);
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 15px 30px var(--soft-dark-shadow);
}

.capability-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 0.1;
  animation: moveBackground 20s linear infinite;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.capability-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 30% 70%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%);
  opacity: 0.5;
  animation: pulseGlow 8s ease-in-out infinite alternate;
}

@keyframes pulseGlow {
  0% { opacity: 0.3; transform: scale(1); }
  100% { opacity: 0.7; transform: scale(1.1); }
}

@keyframes moveBackground {
  0% { background-position: 0 0; }
  100% { background-position: 100px 100px; }
}

.capability-hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  animation: fadeInUp 0.8s ease-out;
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: var(--heading-font-weight, 700);
  color: #ffffff;
}

.capability-hero .subtitle {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  position: relative;
  animation: fadeInUp 1s ease-out;
  color: #ffffff;
  font-weight: 400;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.download-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  cursor: pointer !important;
  position: relative;
  z-index: 10;
  margin-top: 20px;
}

.download-btn:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.download-btn:active {
  transform: translateY(1px);
}

.download-btn svg {
  transition: transform 0.3s ease;
}

.download-btn:hover svg {
  transform: translateX(3px);
}

.download-btn-alt {
  background-color: #e8c883;
  color: #0a2e52;
  margin-left: 10px;
}

.download-btn-alt:hover {
  background-color: #d6b670;
  color: #0a2e52;
}

/* Company Profile Section */
.company-profile {
  padding: 4rem 2rem;
  background: var(--background-light);
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 10px 30px var(--soft-dark-shadow);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.profile-item {
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.profile-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.profile-item:hover::before {
  opacity: 1;
}

.profile-item h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--accent-color);
  font-size: 1.4rem;
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: 700;
}

.info-list, .certification-list, .naics-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list li, .certification-list li {
  margin-bottom: 0.75rem;
  color: var(--text-medium);
  font-weight: 400;
  line-height: 1.7;
}

.certification-list li {
  padding-left: 1.5rem;
  position: relative;
}

.certification-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--accent-color);
  font-weight: bold;
}

.naics-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
  color: var(--text-medium);
  font-weight: 400;
  line-height: 1.7;
}

.naics-code {
  font-weight: bold;
  color: var(--primary-color);
  margin-right: 1rem;
  min-width: 70px;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

/* Core Capabilities Section */
.core-capabilities {
  padding: 4rem 2rem;
  background: var(--background-light);
  border-radius: 16px;
  margin-bottom: 2rem;
}

.capabilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.capability-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.capability-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  opacity: 0.8;
}

.capability-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.capability-icon {
  color: var(--accent-color);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.capability-card:hover .capability-icon {
  transform: scale(1.1);
  color: var(--primary-color);
}

.capability-card h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: 700;
}

.capability-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.capability-list li {
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
  color: var(--text-medium);
  font-weight: 400;
  line-height: 1.7;
}

.capability-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--accent-color);
  font-weight: bold;
}

/* Differentiators Section */
.differentiators {
  padding: 4rem 2rem;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 16px;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 30px var(--soft-dark-shadow);
}

.differentiators h2 {
  color: #ffffff !important;
  text-align: center;
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.differentiators h2::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 60px;
  height: 3px;
  background-color: var(--accent-color);
}

.differentiators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.differentiator {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem;
  border-radius: 16px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.differentiator:hover {
  transform: translateY(-8px);
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
}

.differentiator-icon {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.differentiator:hover .differentiator-icon {
  transform: scale(1.1);
}

.differentiator h3 {
  color: var(--text-light);
  margin-bottom: 1rem;
  font-size: 1.4rem;
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: 600;
}

.differentiator h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background-color: var(--accent-color);
  transition: width 0.3s ease;
}

.differentiator:hover h3::after {
  width: 60px;
}

/* Past Performance Section */
.past-performance {
  padding: 4rem 2rem;
  background-color: var(--capability-light);
  border-radius: 16px;
}

.performance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.performance-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-left: 4px solid var(--accent-color);
}

.performance-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.performance-card h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: 700;
}

.performance-card h4 {
  color: var(--capability-accent);
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.performance-card .project-name {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.performance-card p {
  color: var(--text-medium);
  margin-bottom: 0;
}

/* Call to Action Section */
.call-to-action {
  padding: 4rem 2rem;
  background: var(--primary-color);
  color: var(--text-light);
  text-align: center;
  border-radius: 16px;
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 30px var(--soft-dark-shadow);
}

.call-to-action::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 0.1;
}

.call-to-action h2 {
  color: #ffffff !important;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
}

.call-to-action p {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.contact-btn {
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(var(--primary-rgb), 0.2);
  text-decoration: none;
}

.contact-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(var(--primary-rgb), 0.3);
}

/* Section Headings */
.capability-statement-container h2 {
  color: var(--primary-color);
  margin-bottom: 2rem;
  font-size: clamp(1.8rem, 4vw, 2.2rem);
  position: relative;
  display: inline-block;
  text-align: center;
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: var(--heading-font-weight, 700);
}

.capability-statement-container h2::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  border-radius: 3px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-grid, 
  .capabilities-grid, 
  .differentiators-grid, 
  .performance-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .capability-hero h1 {
    font-size: clamp(1.8rem, 4vw, 2rem);
  }
  
  .capability-hero .subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .download-btn {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
  }

  .company-profile,
  .core-capabilities,
  .differentiators,
  .past-performance,
  .call-to-action {
    padding: 3rem 1.5rem;
  }
  
  .capability-card, 
  .profile-item, 
  .differentiator,
  .performance-card {
    padding: 1.5rem;
  }

  .capability-statement-container h2 {
    font-size: 1.6rem;
  }

  .capability-statement-container h2::after {
    width: 60px;
  }

  .capability-icon, 
  .differentiator-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .capability-card h3,
  .profile-item h3,
  .differentiator h3,
  .performance-card h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .capability-hero {
    padding: 2.5rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: 12px;
  }
  
  .company-profile, 
  .core-capabilities, 
  .differentiators, 
  .past-performance, 
  .call-to-action {
    padding: 2.5rem 1rem;
    margin-bottom: 1.5rem;
    border-radius: 12px;
  }
  
  .capability-card,
  .profile-item,
  .differentiator,
  .performance-card {
    padding: 1.25rem;
    border-radius: 12px;
  }

  .capability-statement-container h2 {
    font-size: 1.4rem;
  }

  .capability-statement-container h2::after {
    width: 50px;
    height: 2px;
  }

  .capability-icon,
  .differentiator-icon {
    font-size: 1.8rem;
  }

  .capability-list li,
  .certification-list li,
  .naics-list li,
  .performance-card p {
    font-size: 0.95rem;
  }

  .download-btn, 
  .contact-btn {
    width: 100%;
    justify-content: center;
  }
}

/* Improve readability with font weights */
.capability-statement-container p {
  color: var(--text-medium);
  font-weight: 400;
  line-height: 1.7;
}

/* Stronger headings */
.profile-item h3,
.capability-card h3,
.performance-card h3 {
  font-weight: 700;
}

/* Enhance text in white cards */
.capability-list li,
.info-list li,
.certification-list li,
.naics-list li {
  color: var(--text-medium);
  font-weight: 400;
  line-height: 1.7;
}

/* Improve differentiator text readability */
.differentiator p {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 1.05rem;
  line-height: 1.7;
}

/* Enhanced font for call to action */
.call-to-action p {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7;
}

/* All headings use heading font family */
.capability-hero h1,
.profile-item h3,
.capability-card h3,
.differentiator h3,
.performance-card h3,
.capability-statement-container h2,
.call-to-action h2 {
  font-family: var(--heading-font-family, 'Montserrat', sans-serif);
  font-weight: var(--heading-font-weight, 700);
}

/* Text colors */
.capability-statement-container p,
.info-list li, 
.certification-list li,
.capability-list li,
.naics-list li {
  color: var(--text-medium);
}

/* Button styling match site-wide buttons */
.download-btn,
.contact-btn {
  font-family: var(--body-font-family, 'Roboto', sans-serif);
  font-weight: 600;
  text-transform: capitalize;
}

/* Fix text colors in blue background areas */
.capability-hero h1,
.capability-hero .subtitle,
.capability-hero p {
  color: #ffffff;
}

/* Ensure differentiator text is white */
.differentiators h2,
.differentiator h3,
.differentiator li {
  color: #ffffff !important;
}

/* Call to action text */
.call-to-action h2,
.call-to-action p {
  color: #ffffff !important;
}

/* Ensure buttons have white text */
.download-btn,
.contact-btn {
  color: #ffffff !important;
}

/* Performance card project name and client color fix */
.performance-card .project-name,
.performance-card h3 {
  color: var(--primary-color);
}

/* PDF Generation Loading Toast */
.pdf-loading-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #0a2e52;
  color: white;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  z-index: 9999;
  font-family: var(--font-primary);
  animation: fadeIn 0.3s ease-out;
}

.pdf-loading-spinner {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  margin-right: 12px;
  animation: spin 1s linear infinite;
}

/* PDF Error Toast */
.pdf-error-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  color: #333;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  z-index: 9999;
  font-family: var(--font-primary);
  border-left: 4px solid #e74c3c;
  max-width: 350px;
  animation: slideIn 0.4s ease-out;
}

.pdf-error-icon {
  font-size: 20px;
  margin-right: 12px;
  line-height: 1;
}

.pdf-error-message {
  flex: 1;
}

.pdf-error-message p {
  margin: 0;
  line-height: 1.4;
}

.pdf-error-message p:first-child {
  margin-bottom: 6px;
}

.pdf-error-close {
  background: none;
  border: none;
  color: #888;
  font-size: 16px;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 10px;
  align-self: flex-start;
}

.pdf-error-close:hover {
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateX(30px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

/* Print styles for browser printing (different from PDF export) */
@media print {
  .capability-hero .download-btn {
    display: none;
  }
  
  .call-to-action .contact-btn {
    display: none;
  }
}
