/* Terms & Conditions Page Styles */
.terms-conditions-container {
    font-family: 'Roboto', sans-serif;
    color: var(--text-medium);
    background-color: var(--background-color);
    max-width: 1100px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

/* Header styles */
.terms-header {
    background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark) 100%);
    color: var(--text-light);
    padding: 3rem 2rem;
    border-radius: 16px;
    margin-bottom: 3rem;
    text-align: center;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.terms-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
    z-index: 1;
}

.terms-header h1 {
    font-size: clamp(1.8rem, 4vw, 2.5rem);
    font-weight: 700;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
}

.terms-header p {
    font-size: clamp(1rem, 2vw, 1.2rem);
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    opacity: 0.9;
    position: relative;
    z-index: 2;
}

/* Content section styles */
.terms-content {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    margin-bottom: 2rem;
    border: 1px solid rgba(var(--primary-rgb), 0.08);
}

.terms-section {
    margin-bottom: 2.5rem;
}

.terms-section:last-child {
    margin-bottom: 0;
}

.terms-section h2 {
    color: var(--primary-color);
    font-size: clamp(1.3rem, 3vw, 1.6rem);
    margin-bottom: 1.2rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.75rem;
}

.terms-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    border-radius: 3px;
}

.terms-section p {
    font-size: clamp(0.95rem, 2vw, 1rem);
    line-height: 1.7;
    color: var(--text-medium);
    margin-bottom: 1.2rem;
}

.terms-section ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1.2rem;
}

.terms-section ul li {
    font-size: clamp(0.95rem, 2vw, 1rem);
    line-height: 1.7;
    color: var(--text-medium);
    margin-bottom: 0.5rem;
}

.terms-section a {
    color: var(--accent-color);
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.terms-section a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--accent-color);
    transition: width 0.3s ease;
}

.terms-section a:hover {
    color: var(--primary-color);
}

.terms-section a:hover::after {
    width: 100%;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .terms-conditions-container {
        padding: 3rem 1.5rem;
    }
    
    .terms-header {
        padding: 2.5rem 1.5rem;
        margin-bottom: 2rem;
    }
    
    .terms-content {
        padding: 2rem 1.5rem;
    }
    
    .terms-section h2 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .terms-conditions-container {
        padding: 2rem 1rem;
    }
    
    .terms-header {
        padding: 2rem 1rem;
        margin-bottom: 1.5rem;
    }
    
    .terms-content {
        padding: 1.5rem 1rem;
        border-radius: 12px;
    }
    
    .terms-section {
        margin-bottom: 2rem;
    }
    
    .terms-section h2 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    
    .terms-section h2::after {
        width: 40px;
        height: 2px;
    }
}