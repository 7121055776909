:root {
    --font-family-base: 'Roboto', sans-serif;
    --font-family-highlight: 'Montserrat', sans-serif;
    --base-padding: 2rem; /* Base padding */
    --base-font-size: 1rem; /* Base font size */
    --section-padding: 2rem; /* Padding for sections */
    --text-color: #333; /* Fallback text color */
    --list-indent: 2rem; /* Indentation for list items */
    --focus-outline-offset: 3px; /* Outline offset for focusable elements */
}

/* Base layout styles with responsive padding */
body#privacy-policy-page {
    font-family: var(--font-family-base);
    color: var(--secondary-color);
    background-color: var(--background-color);
    padding: var(--base-padding);
    font-size: var(--base-font-size); /* Ensure base font size is applied */
}

/* Styling for the header of the privacy policy */
header.intro-policy {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: clamp(1rem, 6vw, var(--base-padding)) 0; /* Responsive vertical padding */
}

header.intro-policy h1 {
    margin-bottom: 1rem;
    font-family: var(--font-family-highlight);
    font-size: clamp(1.5rem, 4vw, 2rem); /* Responsive font size for main title */
}

/* Style for each section of the policy for clear segmentation */
section {
    padding: 0 var(--section-padding);
}

section h2 {
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: clamp(1.125rem, 3.5vw, 1.5rem); /* Responsive font size for section titles */
}

/* General text styles for good readability */
section p, section ul li {
    font-size: var(--base-font-size); /* Apply base font size for consistency */
    line-height: 1.6;
    text-align: left;
    color: var(--text-color);
}

section ul {
    list-style-type: disc;
    margin-left: var(--list-indent);
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

#return-to-top:hover {
    background-color: var(--transparent-secondary-color);
}

:focus {
    outline-offset: var(--focus-outline-offset);
}

/* Responsive design adjustments for different screen sizes */
@media (max-width: 480px) {
    :root {
        --base-font-size: 0.875rem; /* Smaller base font size for smaller screens */
    }
    body#privacy-policy-page {
        padding: 1rem; /* Smaller padding for smaller screens */
    }
    header.intro-policy h1, section h2 {
        font-size: smaller; /* Smaller font size for smaller screens */
    }
    section {
        padding: 0 1rem; /* Smaller padding for smaller screens */
    }
    section ul {
        margin-left: 1.5rem; /* Smaller left margin for list */
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    /* Adjustments for tablet viewports */
}

@media (min-width: 769px) {
    /* Desktop-specific adjustments */
}

/* Privacy Policy Page Styles */
:root {
    --font-family-base: 'Roboto', sans-serif;
    --font-family-highlight: 'Montserrat', sans-serif;
    --base-padding: 2rem;
    --base-font-size: 1rem;
    --section-padding: 2rem;
    --list-indent: 2rem;
    --focus-outline-offset: 3px;
}

/* Base layout styles */
.privacy-policy-container {
    font-family: var(--font-family-base);
    color: var(--text-medium);
    background-color: var(--background-color);
    max-width: 1100px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

/* Header styles */
.privacy-policy-header {
    background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark) 100%);
    color: var(--text-light);
    padding: 3rem 2rem;
    border-radius: 16px;
    margin-bottom: 3rem;
    text-align: center;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.privacy-policy-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
    z-index: 1;
}

.privacy-policy-header h1 {
    font-size: clamp(1.8rem, 4vw, 2.5rem);
    font-weight: 700;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
}

.privacy-policy-header p {
    font-size: clamp(1rem, 2vw, 1.2rem);
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    opacity: 0.9;
    position: relative;
    z-index: 2;
}

/* Content section styles */
.privacy-policy-content {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    margin-bottom: 2rem;
    border: 1px solid rgba(var(--primary-rgb), 0.08);
}

.content-section {
    margin-bottom: 2.5rem;
}

.content-section:last-child {
    margin-bottom: 0;
}

.content-section h2 {
    color: var(--primary-color);
    font-size: clamp(1.3rem, 3vw, 1.6rem);
    margin-bottom: 1.2rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.75rem;
}

.content-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    border-radius: 3px;
}

.content-section p {
    font-size: clamp(0.95rem, 2vw, 1rem);
    line-height: 1.7;
    color: var(--text-medium);
    margin-bottom: 1.2rem;
}

.content-section ul {
    list-style-type: disc;
    margin-left: var(--list-indent);
    margin-bottom: 1.2rem;
}

.content-section ul li {
    font-size: clamp(0.95rem, 2vw, 1rem);
    line-height: 1.7;
    color: var(--text-medium);
    margin-bottom: 0.5rem;
}

.content-section a {
    color: var(--accent-color);
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.content-section a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--accent-color);
    transition: width 0.3s ease;
}

.content-section a:hover {
    color: var(--primary-color);
}

.content-section a:hover::after {
    width: 100%;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 3rem 1.5rem;
    }
    
    .privacy-policy-header {
        padding: 2.5rem 1.5rem;
        margin-bottom: 2rem;
    }
    
    .privacy-policy-content {
        padding: 2rem 1.5rem;
    }
    
    .content-section h2 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .privacy-policy-container {
        padding: 2rem 1rem;
    }
    
    .privacy-policy-header {
        padding: 2rem 1rem;
        margin-bottom: 1.5rem;
    }
    
    .privacy-policy-content {
        padding: 1.5rem 1rem;
        border-radius: 12px;
    }
    
    .content-section {
        margin-bottom: 2rem;
    }
    
    .content-section h2 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    
    .content-section h2::after {
        width: 40px;
        height: 2px;
    }
}
