/* RETURN TO TOP */
.return-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1050;
    pointer-events: none; /* Allow clicking through when icon is hidden */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.return-to-top.visible {
    opacity: 1;
    visibility: visible;
}

.return-to-top-icon {
    color: var(--accent-color);
    cursor: pointer;
    font-size: 2.5rem;
    background-color: rgba(8, 29, 54, 0.8);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    pointer-events: auto; /* Re-enable pointer events on the icon */
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
}

.return-to-top-icon:hover {
    background-color: var(--primary-dark);
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
    color: var(--accent-light);
}

@media screen and (max-width: 768px) {
    .return-to-top {
        right: 15px;
        bottom: 15px;
    }
    
    .return-to-top-icon {
        font-size: 2rem;
        width: 45px;
        height: 45px;
    }
}

@media screen and (max-width: 480px) {
    .return-to-top {
        right: 10px;
        bottom: 10px;
    }
    
    .return-to-top-icon {
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
    }
}
/* END RETURN TO TOP */